<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <v-row>
          <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        </v-row>
        <v-row>
          <v-col cols="9">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <v-col cols="4" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu-fecha-vigencia"
                      v-model="menuFechaVigencia"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigenciaSelected"
                          label="Vigentes al"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigencia = parseDateToIso(
                              fechaVigenciaSelected
                            )
                          "
                          v-on="on"
                          :rules="fechaVigencia != null ? rules.validDate : []"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigencia"
                        no-title
                        @change="
                          fechaVigenciaSelected = formatDate(fechaVigencia)
                        "
                        @input="menuFechaVigencia = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="agente-autocomplete"
                      v-model.trim="agenteSelected"
                      :items="agentes"
                      :search-input.sync="agentesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      :filter="customFilterAgente"
                      outlined
                      clearable
                      hide-no-data
                      hint="Ingrese código o nombre de agente"
                      return-object
                      dense
                      :cache-items="banderaTrue ? banderaTrue : !banderaTrue"
                      autocomplete="off"
                      label="Agente de pago"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="agentesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Origen -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="origen-autocomplete"
                      v-model.trim="origenSelected"
                      :items="origenes"
                      :search-input.sync="origenesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="origenChange(origenSelected)"
                      :filter="customFilterOrigen"
                      outlined
                      hide-no-data
                      hint="Ingrese código o nombre de orígen"
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Orígen"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="origenesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de comprobante -->
                </v-row>
                <v-row>
                  <!-- nombre tabla -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      ref="tablas-autocomplete"
                      v-model.trim="nombreTablaSelected"
                      :items="nombreTablas"
                      :search-input.sync="tablasAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="tablaChange(nombreTablaSelected)"
                      :filter="customFilterTablas"
                      outlined
                      hide-no-data
                      hint="Ingrese número o nombre de tabla"
                      clearable
                      cache-items
                      return-object
                      dense
                      autocomplete="off"
                      label="Nombre de tabla"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="tablasLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Prestador -->
                  <v-col cols="6" md="5" class="py-0">
                    <v-autocomplete
                      ref="prestadores-autocomplete"
                      v-model.trim="prestadoresSelected"
                      :items="prestadores"
                      :search-input.sync="prestadoresAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="prestadorChange(prestadoresSelected.id)"
                      :filter="customFilterPrestadores"
                      outlined
                      hide-no-data
                      hint="Ingrese matrícula o nombre del prestador"
                      clearable
                      cache-items
                      return-object
                      dense
                      autocomplete="off"
                      label="Prestador"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="prestadoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-1 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="1"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card class="mb-4">
          <v-data-table
            :headers="headers"
            item-key="tablaId"
            :items="itemsTablaFac"
            show-expand
            :expanded.sync="expanded"
            :items-per-page="20"
            :footer-props="{
              'items-per-page-options': [20, 40, 60, 80]
            }"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-btn
                      color="primary"
                      class="no-upper-case ml-0 "
                      @click="btnAsignacionTablas"
                    >
                      Procesos de asignación
                    </v-btn>
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <v-btn
                      color="primary"
                      class="no-upper-case ml-0"
                      @click="btnActualizacionTablas"
                    >
                      Procesos de actualización
                    </v-btn>
                  </v-col>
                  <v-col cols="1">
                    <v-row justify="end">
                      <v-btn color="primary" @click="openModal()" class="mr-1">
                        Nuevo
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalAsignacion(item)"
                  >
                    {{ asigIcon }}
                  </v-icon>
                </template>
                <span>Asignar tabla</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="verTabla(item)"
                  >
                    {{ verProc }}
                  </v-icon>
                </template>
                <span>Ver tabla</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteTabla(item.tablaId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{ isExpanded ? chevronUpIcon : chevronDownIcon }}</v-icon
                  >
                </template>
                <span>Ver observaciones</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="12">
                    <strong>Observaciones:</strong>
                    {{ item.observaciones }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" class="text-right py-1">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  align="end"
                  color="primary"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsTablaFac.length < 1"
                  @click="exportExcelModelo"
                >
                  Exportar lista completa
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-14 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalVerTabla"
      v-model="modalVerTabla"
      max-width="60%"
      @keydown.esc="closeModalVerTabla"
      persistent
    >
      <VerTablaConsulta
        :ctaId="ctaId"
        :tablaId="tablaId"
        :tablaNombre="tablaNombre"
        @closeModalVerTabla="closeModalVerTabla"
      ></VerTablaConsulta>
    </v-dialog>
    <v-dialog
      v-if="modalImportTabla"
      v-model="modalImportTabla"
      max-width="60%"
      @keydown.esc="closeModalImportar"
      persistent
    >
      <ImportarTablaFac
        :ctaId="ctaId"
        :tablaId="tablaId"
        :tablaNombre="tablaNombre"
        @closeModalImportar="closeModalImportar"
      ></ImportarTablaFac>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import VerTablaConsulta from "@/components/modules/prestadores/VerTablaConsulta.vue";
import ImportarTablaFac from "@/components/modules/prestadores/ImportarTablaFac.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ConsultaTablaFacturacion",
  components: {
    PageHeader,
    Ayuda,
    DeleteDialog,
    FiltersSelected,
    VerTablaConsulta,
    ImportarTablaFac,
    ConfirmDialog,
    GoBackBtn
  },
  directives: { mask },
  data() {
    return {
      routeToGo: "ConfiguracionPrestadores",
      asigIcon: enums.icons.ASIGN_ICON,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      verProc: enums.icons.SEE,
      optionCode: enums.webSiteOptions.CONSULTA_TABLA_FAC,
      showDeleteModal: false,
      titleDelete: "¿Eliminar tabla?",
      banderaTrue: false,
      newSuffixTabla: "",
      expanded: [],
      nombreTablas: [],
      tablasLoading: false,
      tablasAutocompleteInput: null,
      menuFechaVigencia: null,
      fechaVigencia: null,
      fechaVigenciaSelected: null,
      origenSelected: null,
      itemsTablaFac: [],
      prestadoresSelected: null,
      prestadores: [],
      banderaPrestadorselected: false,
      prestadoresAutocompleteInput: null,
      prestadoresLoading: false,
      nombreTablaSelected: null,
      title: enums.titles.CONSULTA_TABLA_FAC,
      calendarIcon: enums.icons.CALENDAR,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      menuFechaDesde: false,
      rules: rules,
      filtrosGuardados: {},
      showExpand: false,
      showHelp: false,
      showFilters: true,
      filtersApplied: [],
      isFormValid: false,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      loading: false,
      agenteSelected: null,
      agentes: [],
      openModalEditTabla: false,
      agentesAutocompleteInput: null,
      agentesLoading: false,
      banderaOrigenSelected: false,
      banderaTablaSelected: false,
      banderaAgentesSelected: false,
      origenesAutocompleteInput: null,
      search: "",
      infoNuevaActualizacion: null,
      showIcon: true,
      modalImportTabla: false,
      modalVerTabla: false,
      loadingBtn: false,
      origenes: [],
      origenesLoading: false,
      headers: [
        {
          text: "Número tabla",
          value: "tablaId",
          align: "start",
          sortable: false
        },
        {
          text: "Nombre tabla",
          value: "tablaNombre",
          align: "start",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      openModalEdit: false,
      idToEdit: null,
      editNuevoNombre: null,
      ctaId: null
    };
  },
  watch: {
    prestadoresAutocompleteInput(val) {
      if (val && val.length > 2) {
        this.getPrestadoresFilter();
      } else {
        this.$refs["prestadores-autocomplete"].cachedItems = [];
      }
    },
    origenesAutocompleteInput(val) {
      if (val) {
        this.getOrigenesFilter();
      }
    },
    agentesAutocompleteInput(val) {
      if (val) {
        this.getAgentesFilter();
        this.banderaTrue = true;
      } else {
        this.$refs["agente-autocomplete"].cachedItems = [];
      }
    },
    tablasAutocompleteInput(val) {
      if (val) {
        this.getTablasFilter();
      }
    }
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    if (this.$store.state.filtersConsulta != null) {
      this.agenteSelected = this.$store.state.filtersConsulta.agenteId;
      this.origenSelected = this.$store.state.filtersConsulta.oriId;
      this.fechaVigenciaSelected = this.$store.state.filtersConsulta.vigencia;
      this.nombreTablaSelected = this.$store.state.filtersConsulta.nombreTabla;
      this.prestadoresSelected = this.$store.state.filtersConsulta.preId;
      this.newSuffixTabla = this.$store.state.filtersConsulta.periodoNombreTabla;
      this.applyFilters();
    }
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  methods: {
    ...mapActions({
      getAgentePagoByQuery: "prestadores/getAgentePagoByQuery",
      getTablasFacturacion: "prestadores/getTablasFacturacion",
      getTablasFacturacionConsulta: "prestadores/getTablasFacturacionConsulta",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresByQuery",
      fetchOrigenesByQuery: "prestadores/fetchOrigenesByQuery",
      deleteTablaFacturacion: "prestadores/deleteTablaFacturacion",
      setAlert: "user/setAlert"
    }),
    verTabla(item) {
      this.modalVerTabla = true;
      this.tablaId = item.tablaId;
      this.tablaNombre = item.tablaNombre;
      this.ctaId = item.ctaId;
    },
    closeModalVerTabla() {
      this.modalVerTabla = false;
    },
    openModalImport() {
      this.modalImportTabla = true;
    },
    closeModalImportar() {
      this.modalImportTabla = false;
    },
    // metodos de filtros
    async applyFilters() {
      this.$store.state.filtersConsulta = null;
      this.showFilters = false;
      this.loading = true;
      this.customizeFiltersApplied();
      const filters = {
        agenteId: this.agenteSelected ? parseInt(this.agenteSelected.id) : null,
        oriId: this.origenSelected != null ? this.origenSelected.id : null,
        vigencia: this.parseDateToIso(this.fechaVigenciaSelected),
        nombreTabla: this.nombreTablaSelected
          ? this.nombreTablaSelected.value
          : null,
        preId: this.prestadoresSelected,
        periodoNombreTabla: this.newSuffixTabla ? this.newSuffixTabla : null
      };
      this.filtrosGuardados = {
        agenteId: this.agenteSelected,
        oriId: this.origenSelected,
        vigencia: this.fechaVigenciaSelected,
        nombreTabla: this.nombreTablaSelected,
        preId: this.prestadoresSelected,
        periodoNombreTabla: this.newSuffixTabla
      };
      try {
        const response = await this.getTablasFacturacionConsulta(filters);
        this.itemsTablaFac = response.data.data;
        this.loading = false;
        this.showFilters = false;
      } catch {
        this.loading = false;
      }
    },
    tablaChange(data) {
      this.nombreTablaSelected = data;
    },
    customFilterTablas(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    prestadorChange(data) {
      this.prestadoresSelected = data;
    },
    customFilterPrestadores(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    customFilterAgente(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    origenChange(data) {
      this.origenSelected = data;
    },
    customFilterOrigen(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsTablaFac?.forEach(x =>
        result.push({
          ["N° de tabla"]: x.tablaId,
          ["Nombre de tabla"]: x.tablaNombre,
          ["Cuenta de facturación"]: x.ctaFacNom,
          ["Conjunto de aranceles"]: x.grupoAranNom,
          ["Observaciones"]: x.observaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Tablas de facturación");
    },
    getPrestadoresFilter() {
      if (this.timerPrestadores) {
        clearTimeout(this.timerPrestadores);
        this.timerPrestadores = null;
      }
      this.timerPrestadores = setTimeout(async () => {
        if (
          this.prestadores?.some(
            x => x.value == this.prestadoresAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaPrestadorselected) {
          this.prestadoresLoading = true;
          const response = await this.fetchPrestadoresByQuery({
            input: this.prestadoresAutocompleteInput
          });
          this.$refs["prestadores-autocomplete"].cachedItems = [];
          this.prestadores = response;
          this.prestadoresLoading = false;
        } else {
          this.prestadoresLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchPrestadoresByQuery({
            input: this.infoNuevaActualizacion.preId.toString()
          });
          this.prestadores = response;
          this.prestadoresSelected = response[0].id;
          this.banderaPrestadorselected = false;
          this.prestadoresLoading = false;
        }
      }, 1000);
    },
    getAgentesFilter() {
      if (this.timerAgentes) {
        clearTimeout(this.timerAgentes);
        this.timerAgentes = null;
      }
      this.timerAgentes = setTimeout(async () => {
        if (this.agentes?.some(x => x.value == this.agentesAutocompleteInput)) {
          return;
        } else if (!this.banderaAgentesSelected) {
          this.agentesLoading = true;
          const response = await this.getAgentePagoByQuery({
            input: this.agentesAutocompleteInput
          });
          this.$refs["agente-autocomplete"].cachedItems = [];
          this.agentes = response;
          this.agentesLoading = false;
        } else {
          this.agentesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.getAgentePagoByQuery({
            input: this.infoNuevaActualizacion.ageId.toString()
          });
          this.agentes = response;
          this.agenteSelected = response[0].id;
          this.banderaAgentesSelected = false;
          this.agentesLoading = false;
        }
      }, 1000);
    },
    getOrigenesFilter() {
      if (this.timerOrigenes) {
        clearTimeout(this.timerOrigenes);
        this.timerOrigenes = null;
      }
      this.timerOrigenes = setTimeout(async () => {
        if (
          this.origenes?.some(x => x.value == this.origenesAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.origenes = response;
          this.origenesLoading = false;
        } else {
          this.origenesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchOrigenesByQuery({
            input: this.infoNuevaActualizacion.oriId.toString()
          });
          this.origenes = response;
          this.origenSelected = response[0].id;
          this.banderaOrigenSelected = false;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    getTablasFilter() {
      if (this.timerTablas) {
        clearTimeout(this.timerTablas);
        this.timerTablas = null;
      }
      this.timerTablas = setTimeout(async () => {
        if (
          this.nombreTablas?.some(x => x.value == this.tablasAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaTablaSelected) {
          this.tablasLoading = true;
          const response = await this.getTablasFacturacion({
            input: this.tablasAutocompleteInput
          });
          this.$refs["tablas-autocomplete"].cachedItems = [];
          this.nombreTablas = response;
          this.tablasLoading = false;
        } else {
          this.tablasLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.getTablasFacturacion({
            input: this.infoNuevaActualizacion.nombreTabla.toString()
          });
          this.nombreTablas = response;
          this.nombreTablaSelected = response[0].id;
          this.banderaTablaSelected = false;
          this.tablasLoading = false;
        }
      }, 1000);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    saveEditValor() {
      let tablaFac = this.itemsTablaFac.find(x => x.tablaId === this.idToEdit);
      tablaFac.nuevoNombre = this.editNuevoNombre;
      this.openModalEdit = false;
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.agentesAutocompleteInput) {
        this.filtersApplied.splice(0, 1, {
          key: "agentesAutocompleteInput",
          label: "Agente de pago",
          model: this.agentesAutocompleteInput
        });
      }
      if (this.origenesAutocompleteInput) {
        this.filtersApplied.splice(1, 1, {
          key: "origenesAutocompleteInput",
          label: "Orígen",
          model: this.origenesAutocompleteInput
        });
      }
      if (this.fechaVigenciaSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "fechaVigenciaSelected",
          label: "Fecha de vigencia",
          model: this.fechaVigenciaSelected
        });
      }
      if (this.tablasAutocompleteInput) {
        this.filtersApplied.splice(3, 1, {
          key: "tablasAutocompleteInput",
          label: "Nombre de la tabla",
          model: this.tablasAutocompleteInput
        });
      }
      if (this.prestadoresAutocompleteInput) {
        this.filtersApplied.splice(4, 1, {
          key: "prestadoresAutocompleteInput",
          label: "Prestador",
          model: this.prestadoresAutocompleteInput
        });
      }
    },
    openModal(item) {
      this.$router.push({
        name: "NuevaTablaFacturacion",
        params: {
          newTabla: item
        }
      });
      this.$store.state.filtersConsulta = this.filtrosGuardados;
    },
    openModalAsignacion(item) {
      this.$router.push({
        name: "AsignacionTablaFac",
        params: {
          newTabla: item,
          fechaVigencia: this.fechaVigenciaSelected
        }
      });
      this.$store.state.filtersConsulta = this.filtrosGuardados;
    },
    closeAndReload() {
      this.openModalEditTabla = false;
      this.cobradoresParams = {};
      this.applyFilters();
    },
    resetForm() {
      this.agenteSelected = null;
      this.origenSelected = null;
      this.fechaVigenciaSelected = null;
      this.nombreTablaSelected = null;
      this.prestadoresSelected = null;
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    deleteTabla(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTablaFacturacion(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    btnActualizacionTablas() {
      this.$router.push({ name: "ActualizacionTablasFacturacion" });
    },
    btnAsignacionTablas() {
      this.$router.push({ name: "VerProcesoAsignacion" });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
